<template>
    <div class="space-course">
        <img :src="thumbnail" />
        <div class="description">
            <h6 v-if="title.length" class="title">
                {{ title }}
            </h6>
            <EntityTags v-if="tags.length" :tags="tags" />
            <span v-if="description.length" v-html="description"></span>
            <button v-if="!aTag" @click="buttonAction" class="btn btn-info">
                {{ buttonLabel }}
            </button>
            <a :href="aLink" target="_blank" class="btn btn-info" v-else>
                {{ buttonLabel }}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SpaceCourse',
        props: {
            thumbnail: {
                type: String,
                default: require('../assets/no-image.png')
            },
            title: {
                type: String,
                default: ''
            },
            description: {
                type: String,
                default: ''
            },
            tags: {
                type: Array,
                default: () => {
                    return []
                }
            },
            buttonLabel: {
                type: String,
                default: ''
            },
            buttonAction: {
                type: Function,
                default: () => {}
            },
            aTag: {
                type: Boolean,
                default: false
            },
            aLink: {
                type: String,
                default: ''
            }
        },
        components: {
            EntityTags: () => import('./EntityTags.vue')
        }
    }
</script>

<style scoped lang="scss">
    @import '../styles/spaces_common.scss';

    .space-course {
        width: 100%;
        display: flex;
        background-color: #fff;
        box-shadow: $box_shadow;
        flex-wrap: wrap;

        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
            object-position: center;
            margin-right: 0px;
        }

        .description {
            height: auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding: 15px;

            span {
                max-height: -webkit-fill-available;
                overflow: hidden;
                margin-bottom: 1rem;
                text-overflow: ellipsis;
                display: inline-block;
            }

            button,
            a {
                width: 100%;
                text-align: center;
                margin-top: auto;
            }
        }

        @media (min-width: 1600px) {
            flex-wrap: nowrap;

            img {
                width: 50%;
                margin-right: 15px;
                height: auto;
            }

            .description {
                padding: 15px 15px 15px 0;
            }
        }
    }
</style>